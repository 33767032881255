import React, { useState } from 'react'
import Image from 'next/image'
import { RemoveScroll } from 'react-remove-scroll'
import * as Portal from '@radix-ui/react-portal';
import icoSearchSrc from '@/public/img/search.svg'
import icoSearchInputSrc from '@/public/img/search-black.svg'
import icoInputResetSrc from '@/public/img/input-reset.svg'
import icoCloseSrc from '@/public/img/close_modal.svg'
import st from './SearchMobile.module.scss'
import { Flex } from '@/ui'
import { List } from './parts'
import { SearchMethod, useSearchQuery } from '@/api'
import { setSearchBlur } from '@/features/ui'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector, useDebounce } from '@/hooks'
import { appUserSelector } from '@/features/auth'

export const SearchMobile = () => {
    const { i18n } = useTranslation()
    const dispatch = useAppDispatch()
    const user = useAppSelector(appUserSelector)

    const [showSearch, setShowSearch] = useState(false)
    const [query, setQuery] = useState('')
    const [tab, setTab] = useState<SearchMethod>('all')

    function onSearchOpen() {
        setShowSearch(true)
        dispatch(setSearchBlur(true))
    }

    function onSearchClose() {
        setShowSearch(false)
        setQuery('')
        setTab('all')
        dispatch(setSearchBlur(false))
    }

    const queryDebounced = useDebounce(query, 700)
    const showSearchHistory = queryDebounced.length < 2

    const {data, isFetching} = useSearchQuery({
        query: showSearchHistory ? '' : queryDebounced,
        method: tab,
        locale: i18n.language,
    }, {
        skip: !showSearch,
        refetchOnMountOrArgChange: true,
    })

    const searchResult = data?.data?.results ?? []

    return (
        <>
            <button
                className={st.btnTrigger}
                onClick={onSearchOpen}
            >
                <Image
                    src={icoSearchSrc}
                    width={24}
                    height={24}
                    alt=''
                />
            </button>
            {showSearch && (
                <Portal.Root>
                    <RemoveScroll>
                        <Flex className={st.base}>
                            <Flex
                                className={st.inputSection}
                                dir='row'
                                gap={8}
                            >
                                <Flex
                                    className={st.inputWrapper}
                                    dir='row'
                                    gap={8}
                                    grow={1}
                                >
                                    <Image
                                        src={icoSearchInputSrc}
                                        alt=''
                                    />
                                    <label
                                        htmlFor='search-mobile'
                                        className={st.inputLabel}
                                    />
                                    <input
                                        id='search-mobile'
                                        className={st.input}
                                        type='search'
                                        value={query}
                                        onChange={(e) => setQuery(e.target.value)}
                                        placeholder='Найти игрока, команду, лигу ...'
                                        autoComplete='off'
                                        autoFocus
                                    />
                                    {query.length > 0 && (
                                        <button
                                            className={st.btnInputReset}
                                            onClick={() => setQuery('')}
                                        >
                                            <Image
                                                src={icoInputResetSrc}
                                                width={14}
                                                height={14}
                                                alt=''
                                            />
                                        </button>
                                    )}
                                </Flex>
                                <button
                                    className={st.btnClose}
                                    onClick={onSearchClose}
                                >
                                    <Image
                                        src={icoCloseSrc}
                                        width={16}
                                        height={16}
                                        alt=''
                                    />
                                </button>
                            </Flex>
                            <div className={st.listContainer}>
                                <List
                                    items={searchResult}
                                    tabActive={tab}
                                    setTabActive={(v) => setTab(v)}
                                    onSearchClose={onSearchClose}
                                    isListFetching={isFetching}
                                    showSearchHistory={showSearchHistory}
                                    isUser={!!user}
                                />
                            </div>
                        </Flex>
                    </RemoveScroll>
                </Portal.Root>
            )}
        </>
    )
}