import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'

export const selectRoutePrev = createSelector(
    (state: RootState) => state.app.route,
    (state) => state.prev,
)
export const selectSlugType = createSelector(
    (state: RootState) => state.app.route,
    (state) => state.slugType,
)

export const selectSport = (state: RootState) => state.app.sports
export const selectCountries = (state: RootState) => state.app.countries
// only top countries
export const selectCountriesTop = (state: RootState) =>
    state.app.countries.filter((item: any) => item.top)
// all with top countries
export const selectAllCountriesTop = (state: RootState) =>
    state.app.countries.slice().sort(function (x, y) {
        // true values first
        return x.top === y.top ? 0 : x.top ? -1 : 1
        // false values first
        // return (x === y)? 0 : x? 1 : -1;
    })

export const appSportSelector = createSelector(selectSport, (state) => state)

export const appCountriesSelector = createSelector(
    selectCountries,
    (state) => state
)

export const appCountriesTopSelector = createSelector(
    selectCountriesTop,
    (state) => state
)

export const appCompetitionsByCountrySelector = createSelector(
    [selectCountries, (state, id) => id],
    (items, id) => items.filter((item: any) => item.id === id)
)

export const selectFavourites = createSelector(
    (s: RootState) => s.app.favorites,
    (s) => s,
)