import { createAsyncThunk, createAction } from '@reduxjs/toolkit'

import axios from '../../plugins/axios'

export type PayloadCompetitionCountry = {
    sport_id: string
    country_id: string
    index?: string | number
}

export const saveRouteChange = createAction<string>('app/save-route-change')
export const setSlugType = createAction<string>('app/set_slug_type')

export const setModal = createAction<{x: number, y: number} | false>('app/set-modal')

export const setFull = createAction<boolean>('app/set-type-size')

export const setAuthModal = createAction('app/set-auth-modal')

export const getSports = createAsyncThunk('app/sports', async () => {
    const response = await axios.get('sports')

    return response.data
})

export const getRedirectUrl = createAsyncThunk(
    'app/go',
    async ({
        match_id,
        bookmaker_id,
        odd_type,
        odd_select,
        period,
        handicap,
        bonus_id,
    }: any) => {
        const response = await axios.post('go', {
            match_id,
            bookmaker_id,
            odd_type,
            odd_select,
            period,
            handicap,
            bonus_id,
        })

        return response.data
    }
)

export const getCountries = createAsyncThunk('app/countries', async () => {
    const response = await axios.get('countries')

    return response.data
})

export const getCompetitionsByCountry = createAsyncThunk(
    'app/competition_country',
    async ({ sport_id, country_id, index }: PayloadCompetitionCountry) => {
        const response = await axios.get(
            `countrycompetitions/${sport_id}/${country_id}`
        )

        return { data: response.data, country_id }
    }
)

export const getFavorites = createAsyncThunk('app/favorites', async () => {
    const response = await axios.get('favorite')

    return response.data
})

/** Synchronise chosen by guest favorites with server after login */
export const initFavorites = createAsyncThunk(
    'app/init_favorites',
    async () => {
        const favorites: Record<string, number[]> = JSON.parse(localStorage.getItem('favorites'))
        const response = await axios.post(
            'favorite',
            Object.keys(favorites).map((key) => ({
                type: key,
                id: favorites[key],
            })),
        )
        return response.data
    }
)

export const addFavorites = createAsyncThunk(
    'app/add_favorites',
    async ({ type, id }: any) => {
        const response = await axios.post(
            'favorite',
            [{
                type,
                id: [id],
            }]
        )

        return response.data
    }
)

export const removeFavorites = createAsyncThunk(
    'app/remove_favorites',
    async ({ type, id }: any) => {
        const response = await axios({
            url: 'favorite',
            data: {
                type,
                id,
            },
            method: 'delete',
        })

        return response.data
    }
)

export const setFavorites = createAction('app/set-favorites')
