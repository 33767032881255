import { isServerSide } from '@/utils'
import { NextRouter } from 'next/router'

type Props = {
    router: NextRouter
}

// Check same letters in top domain and first url part
const regExpCheckLangDuplicateInUrl = /^https?:\/\/([^\/]+\.)?([a-z]{2})\/\2(\/|$)/;

/** Check slug page url - remove lang from URL if it's already defined by domain.
 *  ex: website.de/de/page -> website.de/page
 * */
export const SlugUrlValidation = ({router}: Props): null => {
    if (isServerSide() || !router.pathname.includes('slug')) return null

    if (regExpCheckLangDuplicateInUrl.test(window.location.href)) {
        router.replace(router.asPath)
    }

    return null
}