import { useTranslation } from 'next-i18next'

export function useCustomTranslation(ns: string) {
    const { t, i18n } = useTranslation(ns)

    const customT = (key: string, options?: object) => {
        // @ts-ignore
        if (options?.format) {
            // @ts-ignore
            options.format = {
                // @ts-ignore
                lastDay: t(options.format + '.lastDay'),
                // @ts-ignore
                sameDay: t(options.format + '.sameDay'),
                // @ts-ignore
                nextDay: t(options.format + '.nextDay'),
                // @ts-ignore
                lastWeek: t(options.format + '.lastWeek'),
                // @ts-ignore
                nextWeek: t(options.format + '.nextWeek'),
                // @ts-ignore
                sameElse: t(options.format + '.sameElse'),
            }
        }
        // @ts-ignore
        return t(key, options).replace(/&#x2F;/g, '.')
    }

    return { t: customT, i18n }
}
