const moment = require('moment');

// @ts-check

const Cookies = require('js-cookie');
/**
 * @type {import('next-i18next').UserConfig}
 */

moment.updateLocale('en', {
    calendar: {
        lastDay: '[Yesterday at] HH:mm',
        sameDay: '[Today at] HH:mm',
        nextDay: '[Tomorrow at] HH:mm',
        lastWeek: '[last] dddd [at] HH:mm',
        nextWeek: 'dddd [at] HH:mm',
        sameElse: 'L',
    },
});

module.exports = {
    // https://www.i18next.com/overview/configuration-options#logging
    debug: false,
    i18n: {
        locales: ['default', 'ru', 'en', 'fr', 'de', 'es', 'it', 'bg', 'cs', 'da', 'el', 'hu', 'nl', 'no', 'pl', 'ptbr', 'ptpt', 'ro', 'sk', 'sr', 'sv', 'tr', 'uk'],
        defaultLocale: 'default',
        localeDetection: false,
        domains: [
            {
                domain: 'oddsocean.de',
                defaultLocale: 'de',
            },
            {
                domain: 'oddsocean.com',
                defaultLocale: 'default',
                locales: ['ru', 'en', 'fr', 'es', 'it', 'bg', 'cs', 'da', 'el', 'hu', 'nl', 'no', 'pl', 'ptbr', 'ptpt', 'ro', 'sk', 'sr', 'sv', 'tr', 'uk'],
            },
        ],
    },
    trailingSlash: true,
    //@todo
    // Если не перечислить все используемые языки, при переключении на язык вне fallbackLng списка сначала подставится первый язык из fallbackLng,
    // потом указанный язык
    fallbackLng: ['en', 'fr', 'de', 'es', 'it', 'bg', 'cs', 'da', 'el', 'hu', 'nl', 'no', 'pl', 'ptbr', 'ptpt', 'ro', 'sk', 'sr', 'sv', 'tr', 'uk', 'ru'],
    /** To avoid issues when deploying to some paas (vercel...) */
    localePath: typeof window === 'undefined'
        ? require('path').resolve('./public/locales')
        : '/public/locales',
    reloadOnPrerender: process.env.NODE_ENV === 'development',
    /**
   * @link https://github.com/i18next/next-i18next#6-advanced-configuration
   */
    //saveMissing: true,
    //strictMode: true,
    serializeConfig: false,

    interpolation: {
        format: function (value, format, lng, options) {
            if (value instanceof Date) {

                let formatAdd = ''

                const str = format;
                const result = str.replace(/{{|}}/g, '').split(/\s*,\s*(?![^\[]*\])/);
                format = result[0];
                if (result[1]) {
                    formatAdd = result[1];
                    if (formatAdd && moment(value * 1000).year() === moment(new Date()).year()) {
                        formatAdd = formatAdd.replace('YYYY', '');
                    }
                }

                switch (format) {
                case 'countdown':
                    const diffTime = value.getTime() - options.dateNow.getTime()
                    const duration = moment.duration(diffTime, 'milliseconds')

                    if (duration.days() > 0) {
                        return duration.locale(lng).humanize();
                    }

                    const hours = duration.hours();
                    const minutes = duration.minutes();
                    const seconds = duration.seconds();

                    const _hours = hours > 0
                        ? String(hours).padStart(2, '0') + ':'
                        : '';
                    const _minutes = String(minutes).padStart(2, '0') + ':';
                    const _seconds = String(seconds).padStart(2, '0');
                    return  _hours + _minutes + _seconds;
                case 'calendar':
                    if (options.format)
                        return moment(value * 1000).locale(lng).calendar(options.format);
                    else
                        return moment(value * 1000).locale(lng).calendar();
                case 'format':
                    return moment(value * 1000).locale(lng).format(formatAdd);
                default:
                    return moment(value * 1000).locale(lng).format(format);
                }
            }

            return value;
        },
    },
    //react: { useSuspense: false }
    onPreInitI18next: (i18n) =>
        i18n.on('languageChanged', (lng) => {
            if (typeof window !== 'undefined') {
                localStorage.setItem('lng', lng);
                // for server side
                Cookies.set('language', lng);
            }
        }),
};