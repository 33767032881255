import Head from 'next/head'
import React from 'react'

type Props = {
    meta: {
        title: string,
        description: string,
        seotext: string,
    } | undefined,
    hreflang: Record<string, string> | undefined,
}

/** Config base meta-data for pages */
export const HeadMeta = ({meta, hreflang}: Props) => (
    <Head>
        <meta
            data-n-head='ssr'
            name='viewport'
            content='viewport-fit=cover, width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no'
        />
        {meta && (
            <>
                <title>
                    {meta.title}
                </title>
                <meta
                    name='description'
                    content={meta.description}
                />
            </>
        )}
        {hreflang && Object.keys(hreflang).map((langKey) => (
            <link
                key={langKey}
                rel='alternate'
                hrefLang={langKey}
                href={hreflang[langKey]}
            />
        ))}
    </Head>
)