import { SwitchButtons } from '@/components/switch-buttons'
import st from '../Search.module.scss'
import { SearchElement, SearchMethod } from '@/api'
import React from 'react'
import Image from 'next/image'
import icoSearchNotFoundSrc from '@/public/img/search-not-found-white.svg'
import { Flex } from '@/ui'
import { SearchItem, SearchItemInHistory } from './SearchItem'

const tabsArray: {id: SearchMethod, label: string}[] = [
    {
        id: 'all',
        label: 'все',
    },
    {
        id: 'participant',
        label: 'команды',
    },
    {
        id: 'competition',
        label: 'турниры',
    },
    {
        id: 'match',
        label: 'матчи',
    },
    {
        id: 'bookmaker',
        label: 'букмекеры',
    },
]

type Props = {
    items: SearchElement[],
    tabActive: SearchMethod,
    setTabActive: (tab: SearchMethod) => void,
    onSearchClose: () => void,
    isListFetching: boolean,
    showSearchHistory: boolean,
    isUser: boolean,
}

export const List = ({
    items,
    tabActive,
    setTabActive,
    onSearchClose,
    isListFetching,
    showSearchHistory,
    isUser,
}: Props) => {
    const searchHistory: SearchElement[] = []
    const searchSuggestions: SearchElement[] = []
    if (showSearchHistory) {
        items.forEach((item) => {
            if (item.type === 0) searchHistory.push(item)
            if (item.type === 1) searchSuggestions.push(item)
        })
    }
    return (
        <>
            <SwitchButtons
                className={st.tabsContainer}
                value={tabActive}
                onChange={(v) => setTabActive(v as SearchMethod)}
                options={tabsArray}
                mode='primary'
            />
            {isListFetching && (
                <>
                    <div className={st.loadPill} />
                    <div className={st.loadPill} />
                    <div className={st.loadPill} />
                    <div className={st.loadPill} />
                    <div className={st.loadPill} />
                </>
            )}
            {!isListFetching && items.length === 0 && (
                <Flex
                    className={st.notFound}
                    align='center'
                    justify='center'
                    gap={8}
                    grow={1}
                >
                    <Image
                        className={st.notFoundIco}
                        src={icoSearchNotFoundSrc}
                        width={40}
                        height={40}
                        alt=''
                    />
                    Ничего не найдено
                </Flex>
            )}
            {!isListFetching && items.length > 0 && (showSearchHistory ? (
                <>
                    {searchHistory.length > 0 && (
                        <>
                            <div className={st.sectionHeader}>
                                История поиска
                            </div>
                            {searchHistory.map((item) => (
                                <SearchItemInHistory
                                    key={item.id}
                                    item={item}
                                    onClick={onSearchClose}
                                />
                            ))}
                        </>
                    )}
                    {searchSuggestions.length > 0 && (
                        <>
                            <div className={st.sectionHeader}>
                                предложенные
                            </div>
                            {searchSuggestions.map((item) => (
                                <SearchItem
                                    key={item.id}
                                    item={item}
                                    onClick={onSearchClose}
                                    isUser={isUser}
                                />
                            ))}
                        </>
                    )}
                </>
            ) : (
                <>
                    {items.map((item) => (
                        <SearchItem
                            key={item.id}
                            item={item}
                            onClick={onSearchClose}
                            isUser={isUser}
                        />
                    ))}
                </>
            ))}
        </>
    )
}