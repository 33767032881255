import Image from 'next/image'
import search from '@/public/img/search.svg'
import closeIco from '@/public/img/close.svg'
import st from './Search.module.scss'
import { Flex } from '@/ui'
import React, { useEffect, useRef, useState } from 'react'
import { SearchMethod, useSearchQuery } from '@/api'
import { useTranslation } from 'react-i18next'
import useOutsideAlerter from '../../hooks/outsideClick'
import { List } from './parts'
import { useAppDispatch, useAppSelector, useDebounce } from '@/hooks'
import { appUserSelector } from '@/features/auth'
import { setSearchBlur } from '@/features/ui'
import { RemoveScroll } from 'react-remove-scroll'

const dropdownHeightInitial = 360

//@todo Закрытие списка на esc
export const Search = () => {
    const { i18n } = useTranslation()
    const dispatch = useAppDispatch()
    const user = useAppSelector(appUserSelector)

    const refContainer = useRef<HTMLDivElement>(null)
    const refDropdownContent = useRef<HTMLDivElement>(null)
    const [query, setQuery] = useState('')
    const [tab, setTab] = useState<SearchMethod>('all')
    const [isSearchActive, setIsSearchActive] = useState(false)
    const [dropdownHeight, setDropdownHeight] = useState(dropdownHeightInitial)

    function onSearchOpen() {
        setIsSearchActive(true)
        dispatch(setSearchBlur(true))
    }
    function onSearchClose() {
        setQuery('')
        setTab('all')
        setIsSearchActive(false)
        setDropdownHeight(dropdownHeightInitial)
        dispatch(setSearchBlur(false))
    }
    useOutsideAlerter(refContainer, () => onSearchClose())

    const queryDebounced = useDebounce(query, 700)
    const showSearchHistory = queryDebounced.length < 2

    const {data, isFetching} = useSearchQuery({
        query: showSearchHistory ? '' : queryDebounced,
        method: tab,
        locale: i18n.language,
    }, {
        skip: !isSearchActive,
        refetchOnMountOrArgChange: true,
    })

    useEffect(() => {
        if (isFetching) {
            setDropdownHeight(dropdownHeightInitial)
            return
        }
        setDropdownHeight(refDropdownContent.current?.clientHeight ?? dropdownHeightInitial)
    }, [isFetching])

    const searchResult = data?.data?.results ?? []

    return (
        <div
            className={st.base}
            ref={refContainer}
        >
            <Flex
                className={st.inputWrapper}
                dir='row'
                align='center'
                gap={8}
            >
                <Image
                    src={search}
                    alt='Icon search'
                />
                <label
                    htmlFor='search'
                    className={st.inputLabel}
                />
                <input
                    id='search'
                    className={st.input}
                    type='search'
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onFocus={onSearchOpen}
                    placeholder='Найти игрока, команду, лигу ...'
                    autoComplete='off'
                />
                {query.length > 0 && (
                    <button
                        className={st.close}
                        onClick={() => setQuery('')}
                    >
                        <Image
                            src={closeIco}
                            width={16}
                            height={16}
                            alt='Icon close'
                        />
                    </button>
                )}
            </Flex>
            {isSearchActive && (
                <RemoveScroll>
                    <div
                        className={st.dropdown}
                        style={{
                            height: dropdownHeight + 'px',
                        }}
                    >
                        <Flex refOuter={refDropdownContent}>
                            <List
                                items={searchResult}
                                tabActive={tab}
                                setTabActive={(v) => setTab(v)}
                                onSearchClose={onSearchClose}
                                isListFetching={isFetching}
                                showSearchHistory={showSearchHistory}
                                isUser={!!user}
                            />
                        </Flex>
                    </div>
                </RemoveScroll>
            )}
        </div>
    )
}
